const React = require("react");
const { Helmet } = require("react-helmet");

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Experts Circle: A disruptive recruitment platform </title>
        <script
          defer
          id="io.birdseed.script-tag"
          type="text/javascript"
          src="https://cdn.birdseed.io/widget.js"
        ></script>
        <script
          type="text/javascript"
          src="https://app.termly.io/embed.min.js"
          data-auto-block="on"
          data-website-uuid="0ad8db18-e686-4583-9afd-29c21eb9f1bb"
        ></script>
        <script type="text/javascript" src="./index.js"></script>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>

      </Helmet>
      <div>
        <div
          style={{ zIndex: 1000 }}
          id="birdseed-widget-container"
          data-token="f20ff0efcd929813cce4eb0231a16df1"
        ></div>
        {element}
      </div>
    </>
  );
};
